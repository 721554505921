import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DynamicIcon from './DynamicIcon';
import {FaCheck, FaQuestionCircle} from 'react-icons/fa';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import {useTranslation} from "react-i18next";

const Pricing = () => {
    const { t } = useTranslation();
    const pricingPlans = [
        {
            title: t('plan_1_title'),
            price: t('plan_1_price'),
            cycle: t('plan_1_cycle'),
            icons: [
                "check",
                "user",
                "check",
                "check",
            ],
            img: require('../images/user-1.png'),
            features: t('plan_1_feature').split(','),
            pops: t('plan_1_pop').split(','),
        },
        {
            title: t('plan_2_title'),
            price: t('plan_2_price'),
            cycle: t('plan_2_cycle'),
            icons: [
                "check",
                "user",
                "check",
                "check",
            ],
            img: require('../images/user-2.png'),
            features: t('plan_2_feature').split(','),
            pops: t('plan_2_pop').split(','),
        },
        {
            title: t('plan_3_title'),
            price: t('plan_3_price'),
            cycle: t('plan_3_cycle'),
            icons: [
                "check",
                "user",
                "check",
                "check",
            ],
            img: require('../images/user-3.png'),
            features: t('plan_3_feature').split(','),
            pops: t('plan_3_pop').split(','),
        },
    ];
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (

        <section className="pricing" id="pricing">
            <div className="container">
                <div className="content">
                    <h1 className="text-center">{t('plan_choose')}</h1>
                    <h5 className="text-center">{t('plan_choose2')}</h5>
                </div>
                <div className="pricing-cards">
                    <div className="row">
                    {pricingPlans.map(plan => (
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="plans">
                                <div className="card" style={{width: '20rem'}} data-tilt data-tilt-max="5"
                                     data-tilt-axis="x" data-tilt-speed="400" data-tilt-perspective="500">
                                    <img src={plan.img} className="card-img-top" alt={plan.title}/>
                                        <div className="card-body">
                                            <h5 className="card-title text-center">{plan.title}</h5>
                                            <ul className="list-group list-group-flush f-list" style={{textAlign: "left"}}>
                                                {plan.features.map((feature,index) => (
                                                    <li key={feature} className="list-group-item">
                                                        {/*<DynamicIcon className="icon" data-feather={}></DynamicIcon>*/}
                                                        {/*<DynamicIcon library="fa" name={plan.icons.index} size="24px" />*/}
                                                        <FaCheck></FaCheck>
                                                        {feature}
                                                        {plan.pops[index]? (
                                                            <span>
                                                                <span data-tooltip-content={plan.pops[index]} data-tooltip-id={"plan-tooltip"+plan.id}><FaQuestionCircle></FaQuestionCircle></span>
                                                            <ReactTooltip id={"plan-tooltip"+plan.id} effect="solid" />
                                                            </span>
                                                        ) : ''}


                                                    </li>
                                                ))}
                                            </ul>
                                            <h5 className="text-center"> {plan.price} {(plan.price != 'free' && plan.price != 'Free') ? <span>/ {plan.cycle}</span> : ''}</h5>
                                            {/*<a href="#" className="btn-subscribe ">Select</a>*/}
                                        </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
            </div>
            </div>
        </section>
    );
};

export default Pricing;

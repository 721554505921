import React, {useState} from 'react';
import logo from '../images/Logo.png'; // Adjust path as necessary
import { useTranslation } from 'react-i18next';
import {FaHome, FaBuffer, FaTag} from 'react-icons/fa';
import { FiMenu } from "react-icons/fi";
const Header = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    /*const toggleNav = ()=>{
        setShow(!show);
    }*/
    const [collapsing, setCollapsing] = useState(false);

    const toggleNav = () => {
        if (show) {
            // 如果当前是显示状态，我们先设置为折叠中
            setCollapsing(true);
            setShow(false);
            // 设置一个小的延时模拟折叠动画，然后再隐藏
            setTimeout(() => {
                setCollapsing(false);
            }, 350); // 这里的时间应与CSS中的过渡时间相匹配
        } else {
            // 展开导航
            setCollapsing(true);
            setShow(true);
            setTimeout(() => {
                setCollapsing(false);
            }, 20); // 给足够时间让浏览器应用collapsing样式
        }
    };
    return (
        <header className="navigation">
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <a className="navbar-brand" href="#">
                        <img src={logo} alt="FlyFlow logo" style={{maxWidth: '146px', height: "auto"}}/>
                    </a>
                    <button onClick={toggleNav} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <FiMenu></FiMenu>
                    </button>
                    {/*<div className={"collapse navbar-collapse " + (show?'show':'')}
                         id="navbarSupportedContent"
                    >*/}
                    <div
                        className={`navbar-collapse collapse ${show ? 'show' : ''}`}
                        style={{ height: show ? '100px': '0', opacity: show?'1':'0' }}
                        onTransitionEnd={() => {
                            // 动画完成后设置高度为auto或0
                            if (show) {
                                setCollapsing(false);
                            }
                        }}
                         id="navbarSupportedContent"
                    >
                    <ul className="navbar-nav ml-auto">
                            <li className="nav-item active">
                                <a className="nav-link" href="#">
                                    <FaHome></FaHome>
                                    <span style={{marginLeft: "10px"}}>
                                        {t('Home')}
                                    </span>
                                </a>
                            </li>
                            <li className="nav-item active">
                                <a className="nav-link" href="#features">
                                    <FaBuffer></FaBuffer>
                                    <span style={{marginLeft: "10px"}}>
                                        {t('Feature')}
                                    </span>

                                </a>
                            </li>
                            <li className="nav-item active">
                                <a className="nav-link" href="#pricing">
                                    <FaTag></FaTag>
                                    <span style={{marginLeft: "10px"}}>
                                        {t('Pricing')}
                                    </span>
                                </a>
                            </li>
                            {/*<li className="nav-item active">
                                <a className="nav-link" href="#help">帮助</a>
                            </li>*/}
                        </ul>
                        <div className="navbar-btn">
                            <a href="/user/" className="btn-1">{t('Web')}</a>
                            <a href="./page/download" className="btn-fill">{t('Download')}</a>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Header;

import React from 'react';
import bannerImage from '../images/Illustration-1.png';
import { useTranslation } from 'react-i18next';

const Banner = () => {
    const {t} = useTranslation();
    return (
        <section className="banner">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="content-box">
                            <h1>{t('banner_title')} <strong>FlyFlow.</strong></h1>
                            <p>{t('banner_subtitle')}</p>
                            <div className="button-fill">
                                <a href="./page/download/">{t('download_with_trail')}</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <img src={bannerImage} alt="" style={{ maxWidth: '100%', padding: '10px 0' }} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner;

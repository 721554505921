import React from 'react';
import { useEffect, useRef, useState } from 'react';
import TweenOne from 'rc-tween-one';

import img1 from '../images/feature/hBbIHzUsSbSxrhoRFYzi.svg';
import img2 from '../images/feature/BISfzKcCNCYFmTYcUygW.svg';
import img3 from '../images/feature/JsixxWSViARJnQbAAPkI.svg';
import img4 from '../images/feature/pbmKMSFpLurLALLNliUQ.svg';
import img5 from '../images/feature/aLQyKyUyssIUhHTZqCIb.svg';
import img6 from '../images/feature/RpJIQitGbSCHwLMimybX.svg';
import { useTranslation } from 'react-i18next';


const useIntersectionObserver = (options = {}) => {
    const ref = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            // Update our state when observer callback fires
            setIsVisible(entry.isIntersecting);
        }, options);

        const currentRef = ref.current;
        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [ref, options]); // Empty array ensures that effect is only run on mount and unmount

    return [ref, isVisible];
};

const Features = () => {

    const { t } = useTranslation();
// Define the features list
    const features = [
        {
            id: 1,
            title: t("feature_encryption"),
            description: t('feature_encryption_desc'),
            imagePath: img1,
        },
        {
            id: 2,
            title: t("feature_cross"),
            description: t("feature_cross_desc"),
            imagePath: img2,
        },
        {
            id: 3,
            title: t("feature_node"),
            description: t("feature_node_desc"),
            imagePath: img3,
        },
        {
            id: 4,
            title: t("feature_speed"),
            description: t("feature_speed_desc"),
            imagePath: img4,
        },
        {
            id: 5,
            title: t("feature_sla"),
            description: t("feature_sla_desc"),
            imagePath: img5,
        },
        {
            id: 6,
            title: t("feature_support"),
            description: t("feature_support_desc"),
            imagePath: img6,
        }
    ];
    const [ref, isVisible] = useIntersectionObserver({
        root: null,
        rootMargin: '0px',
        threshold: 0.5
    });
    const [hoverStates, setHoverStates] = useState({});
    const [hoverStates2, setHoverStates2] = useState({});

    const [animationsPoint,setAnimations] = useState({});

    const getAnimation = (id, isHovered) => {
        // console.log(id, isHovered)
        const baseAnimation = {
            duration: 3000,
            yoyo: true,
            repeat: -1,
        };
        if (!hoverStates2[isHovered]) return {};

        switch (id) {
            case 'pointRing':
                return {
                    ...baseAnimation,
                    // rotate: 360,
                    // scale: 1.5,
                    translateY: '1px', //['10px', '-10px'],
                };
            case 'point0Left':
            case 'point0Right':
                return {
                    ...baseAnimation,
                    // scale: 1.5,
                    // translateX: id === 'point0Left' ? '-10px' : '10px',
                    translateY: 1,
                };
            case 'point1':
            case 'point2':
            case 'point3':
                return {
                    ...baseAnimation,
                    // scale: 1.5,
                    translateY: 1, // ['10px', '-10px'],
                    // translateX: ['10px', '-10px'],
                };
            default:
                console.log(id)
                return {};
        }
    };

    const handleMouseEnter = (id) => {
        setHoverStates(prev => ({ ...prev, [id]: true }));
        setHoverStates2(prev => ({ ...prev, [id]: true }));
        /*setAnimations(prev => ({
            ...prev,
            ['pointWrapper']: {
                translateY: 20, // Move down 20px
                duration: 1000,
                yoyo: true, // Play forward and backward
                repeat: -1, // Infinite loop
            }
        }));*/
    };

    const handleMouseLeave = (id) => {
        setHoverStates(prev => ({ ...prev, [id]: false }));
            /*setAnimations(prev => ({
                ...prev,
                ['pointWrapper']: {} // Reset the animation
            }));*/
    };
    const [animation, setAnimation] = useState({});


    return (//"features reveal"
        <section className={`features ${isVisible ? 'reveal_visible' : ''}`} id="features" ref={ref}>
            <div className="container home-page page1" id="features">
                <div className="home-page-wrapper" id="page1-wrapper">
                    <h1 className="text-center mb-5">{t("feature_title")}</h1>
                    <h5></h5>
                    <div className="title-line-wrapper page1-line">
                        <div className="title-line"></div>
                    </div>
                    <div className="row page1-box-wrapper">
                            {features.map(feature => (
                                <div key={feature.id} className="col-12 col-sm-1 col-md-4 col-lg-4 col-xl-4 page1-box"
                                     onMouseEnter={() => handleMouseEnter(feature.id)}
                                     onMouseLeave={() => handleMouseLeave(feature.id)}
                                >

                                    <div className="page1-point-wrapper">
                                        <TweenOne component="i" className="point-0 left tween-one-entering"
                                                  animation={getAnimation('point0Left', feature.id)}
                                                  style={{
                                                      background: 'rgb(114, 46, 209)',
                                                      borderColor: 'rgb(114, 46, 209)',
                                                      opacity: 0.4,
                                                      transform: 'translate(-30px, -10.2983px)',
                                                  }} />
                                        <TweenOne component="i" className="point-0 right tween-one-entering"
                                                  animation={getAnimation('point0Right', feature.id)}
                                                  style={{
                                                      background: 'rgb(114, 46, 209)',
                                                      borderColor: 'rgb(114, 46, 209)',
                                                      opacity: 0.4,
                                                      transform: 'translate(20px, -32.3915px)',
                                                  }} />
                                        <TweenOne component="i" className="point-ring tween-one-entering"
                                                  animation={getAnimation('pointRing', feature.id)}
                                                  style={{
                                                      background: 'rgb(114, 46, 209)',
                                                      borderColor: 'rgb(114, 46, 209)',
                                                      opacity: 0.4,
                                                      transform: 'translate(-65px, 3.38459px)',
                                                  }} />
                                        <TweenOne component="i" className="point-1 tween-one-entering"
                                                  animation={getAnimation('point1', feature.id)}
                                                  style={{
                                                      background: 'rgb(114, 46, 209)',
                                                      borderColor: 'rgb(114, 46, 209)',
                                                      opacity: 0.4,
                                                      transform: 'translate(-45px, 90.7644px)',
                                                  }} />
                                        <TweenOne component="i" className="point-2 tween-one-entering"
                                                  animation={getAnimation('point2', feature.id)}
                                                  style={{
                                                      background: 'rgb(114, 46, 209)',
                                                      borderColor: 'rgb(114, 46, 209)',
                                                      opacity: 0.4,
                                                      transform: 'translate(35px, 9.81576px)',
                                                  }} />
                                        <TweenOne component="i" className="point-3 tween-one-entering"
                                                  animation={getAnimation('point3', feature.id)}
                                                  style={{
                                                      background: 'rgb(114, 46, 209)',
                                                      borderColor: 'rgb(114, 46, 209)',
                                                      opacity: 0.2,
                                                      transform: 'translate(50px, 54.0875px)',
                                                  }} />
                                    </div>
                                    <TweenOne
                                        className="page1-image"
                                        animation={{
                                            translateY: hoverStates[feature.id] ? '-8px' : '0px',
                                            boxShadow: hoverStates[feature.id] ? 'rgba(114, 46, 209, 0.12) 0px 12px 24px' : 'rgba(114, 46, 209, 0.12) 0px 6px 12px',
                                            duration: 300
                                        }}>
                                        <img src={feature.imagePath} alt={feature.title} />
                                    </TweenOne>
                                        <h3>{feature.title}</h3>
                                        <p>{feature.description}</p>
                                </div>

                            ))}
                        {/*<ul className="page1-box-wrapper">
                        </ul>*/}
                    </div>
                </div>
        </div>
        </section>
    );
}

export default Features;

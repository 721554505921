import React, { createContext, useContext, useEffect, useState } from 'react';
import i18n from './i18n'; // Make sure this path is correct to your i18n config
import Cookies from 'js-cookie';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(() => Cookies.get('language') || 'zh-CN');

    useEffect(() => {
        i18n.changeLanguage(language); // Ensure i18next is aware of the current language
    }, [language]);

    const changeLanguage = (lang) => {
        setLanguage(lang);
        i18n.changeLanguage(lang); // Change the language in i18next
        Cookies.set('language', lang, { expires: 7 }); // Update the cookie to persist the language choice
    };

    return (
        <LanguageContext.Provider value={{ language, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => useContext(LanguageContext);

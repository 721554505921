import React from 'react';
// In your component or App.js
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-tooltip/dist/react-tooltip.css'

import Header from './components/Header';
import Banner from './components/Banner';
import Features from './components/Features';
import Pricing from './components/Pricing';
import Footer from './components/Footer';
import Stream from './components/Stream';
import { LanguageProvider } from './LanguageProvider'; // Import the provider

function App() {
    return (
        <LanguageProvider>
        <div>
            <Header />
            <Banner />
            <Features />
            <Pricing />
            <Stream />
            <Footer />
        </div>
        </LanguageProvider>
    );
}

export default App;

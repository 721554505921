import React, { useState } from 'react';
import { useLanguage } from '../LanguageProvider';
import {useTranslation} from "react-i18next"; // Import the hook and provider

// import logo from '../images/Logo.png'; // Adjust path as necessary

const Footer = () => {
    const { language, changeLanguage } = useLanguage();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const handleLanguageChange = (lang) => {
        changeLanguage(lang);
        setDropdownOpen(false);
    };
    const { t } = useTranslation();

    return (
        <footer>
            <div className="container">
                <div className="subscribe">
                    <div className="row">
                        <div className="col-md-8">
                            <h1>{t('footer_begin')}</h1>
                            <h5>{t('footer_sub')}</h5>
                        </div>
                        <div className="col-md-4">
                            <div className="btn-subscribe">
                                <a href="./page/download">{t('Download')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-new">
                <div className="footer-flex" style={{justifyContent: 'flex-start' }}>
                    <div className="footer-dropdown">
                        <button onClick={toggleDropdown} style={buttonStyle}>Language</button>
                        {dropdownOpen && (
                            <div className="footer-dropdown-menu">
                                <a href="#" onClick={() => handleLanguageChange('zh-CN')}>简体中文</a>
                                <a href="#" onClick={() => handleLanguageChange('zh-HK')} >繁体中文</a>
                                <a href="#" onClick={() => handleLanguageChange('en')}>English</a>
                                <a href="#" onClick={() => handleLanguageChange('esp')}>Español</a>
                            </div>
                        )}
                    </div>
                </div>
                <div className="footer-user">
                    <div className="footer-flex" style={{ justifyContent: 'center' }}>
                        <a href="/page/user-terms" style={linkStyle}>{t('footer_agreement')}</a>
                        <a href="/page/privacy-policy" style={linkStyle}>{t('footer_privacy')}</a>
                        <a href="/page/about-us" style={linkStyle}>{t('footer_about')}</a>
                    </div>
                    <div className="footer-flex" style={{ justifyContent: 'flex-end' }}>
                        <span style={copyRightStyle}>Copyright © 2021-2024 FlyFlow, Ltd. All rights reserved.</span>
                    </div>
                </div>
            </div>
        </footer>
    );
}

const copyRightStyle = {
    whiteSpace: 'nowrap'
};

const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: '#222',
    border: 'none',
    color: 'white',
    cursor: 'pointer'
};

const linkStyle = {
    margin: '0 10px',
    color: 'white',
    textDecoration: 'none'
};


export default Footer;

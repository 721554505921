import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const clients = [
    { name: 'Netflix', number: '1' },
    { name: 'Reddit', number: '2' },
    { name: 'Amazon', number: '3' },
    { name: 'Discord', number: '4' },
    { name: 'Spotify', number: '5' },
];

const Stream = () => {
    // Configuration for react-slick
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,           // Enables autoplay
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <section className="clients">
            <div className="container">
                <Slider {...settings}>
                    {clients.map(client => {
                        const imageUrl = require(`../images/client-${client.number}.svg`);
                        return (
                            <div key={client.name} class="index-slide-img" style={{ width: '150px', height: 'auto'}}>
                                <img src={imageUrl} alt={client.name} style={{ width: '150px', height: 'auto', display: 'block' }} />
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </section>
    );
}

export default Stream;

import React, { useState, useEffect } from 'react';

const DynamicIcon = ({ library = "fa", name = 'beer', size = '1em' }) => {
    const [Icon, setIcon] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // const Icon =
        import("react-icons/fa").then(m=>setIcon(m["FaBeer"]));

        /*
        setLoading(true);
        // Dynamic import based on the icon name
        import('react-icons/fa')///${library}`)
            .then((icons) => {
                console.log("loaded")
                const iconName = `Fa${name.charAt(0).toUpperCase() + name.slice(1)}`;  // Convert name to expected export convention
                console.log("iconName", iconName, icons['FaBeer'])
                setIcon(icons["FaBeer"]);
                setLoading(false);
            })
            .catch(() => {
                console.error('Icon could not be loaded.');
                setLoading(false);
            });*/
    }, [name, library]);

    //if (loading) return <p>Loading...</p>;
    //if (!Icon) return <p>Icon not found.</p>;

    return <Icon size={size} />;
};

export default DynamicIcon;
